import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TypeNavigation from '../TypeNavigation';
import API from '../../services/API';

const TASK_STATUS_CREATED = 'created';
const TASK_STATUS_RUN = 'run';
const TASK_STATUS_FINISHED = 'finished';
const TASK_STATUS_FAILED = 'failed';
const TASK_STATUS_CANCELED = 'canceled';

class ConversionFollowProgress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      waitMore: false,
      progress: 0,
    };
    this.checkingErrorNumber = 0;
    this.nextCheck = null;
    this.waitMoreTimer = null;
    this.checkTask = this.checkTask.bind(this);
    this.setProgress = progress => this.setState({ progress });
    this.setWaitMore = waitMore => this.setState({ waitMore });
  }
  componentDidMount() {
    this.startTaskChecking();
  }
  componentWillUnmount() {
    this.endTaskChecking();
    this.setProgress = () => {};
    this.setWaitMore = () => {};
  }
  render() {
    return (
      <div className="view" style={{ cursor: 'wait' }}>
        <TypeNavigation />
        <div className="p-5 view-body">
          <div className="col-md-4 offset-4">
            <div className="d-flex flex-column">
              <p className="mb-3">
                <span
                  className="spinner-border spinner-border-lg"
                  role="status"
                  aria-hidden="true"
                ></span>
              </p>
              <p className="p-1">
                {this.state.waitMore && (
                  <p className="mb-0">
                    It may take more time.
                    <br />
                    Please wait.
                  </p>
                )}
                Converting <span>{this.state.progress}%</span>...
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  startTaskChecking() {
    this.nextCheck = setTimeout(() => this.checkTask(), 1000);
  }
  endTaskChecking() {
    clearTimeout(this.waitMoreTimer);
    clearTimeout(this.nextCheck);
    this.waitMoreTimer = null;
    this.nextCheck = null;
  }
  handleTaskStatus() {
    if (this.checkingErrorNumber >= 3) {
      this.endTaskChecking();
      this.props.onError(new Error('Something went wrong.'));
      return;
    }

    switch (this.status) {
      case TASK_STATUS_FINISHED:
        this.updateProgress();
        this.endTaskChecking();
        this.setProgress(100);
        clearTimeout(this.waitMoreTimer);
        this.waitMoreTimer = null;
        this.props.onSuccess(this.props.task);
        break;
      case TASK_STATUS_RUN:
        this.updateProgress();
        this.nextCheck = setTimeout(() => this.checkTask(), 1000);
        break;
      case TASK_STATUS_CREATED:
        this.updateProgress();
        this.nextCheck = setTimeout(() => this.checkTask(), 1000);
        break;
      case TASK_STATUS_FAILED:
      case TASK_STATUS_CANCELED:
      default:
        this.props.onError(new Error('File conversion is failed'));
    }
  }
  checkTask() {
    const { task } = this.props;

    if (!task) {
      this.props.onError(new Error('File conversion is failed'));
      return;
    }

    API.get(`/api/tasks/${task.id}`)
      .then(({ data: task }) => {
        this.status = task.status;
        this.setProgress(task.progress);
        this.handleTaskStatus();
      })
      .catch(() => {
        this.checkingErrorNumber += 1;
        this.handleTaskStatus();
      });
  }
  updateProgress() {
    if (this.state.progress >= 90 && !this.waitMoreTimer) {
      this.waitMoreTimer = setTimeout(() => {
        this.setWaitMore(true);
      }, 30000);
    }
  }
}

ConversionFollowProgress.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

export default ConversionFollowProgress;
