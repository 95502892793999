import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

class TypeNavigation extends Component {
  render() {
    return (
      <div className="d-flex flex-row col-md-12 justify-content-center mb-4 quick-switch">
        <strong className="p-2">Quick switch:</strong>
        <div className="dropdown">
          <button
            className="btn btn-select dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <Switch>
              <Route
                exact
                path="/word-to-pdf"
                component={() => <>Word to PDF</>}
              />
              <Route
                exact
                path="/excel-to-pdf"
                component={() => <>Excel to PDF</>}
              />
              <Route
                exact
                path="/ppt-to-pdf"
                component={() => <>PPT to PDF</>}
              />
              <Route
                exact
                path="/jpeg-to-pdf"
                component={() => <>JPG to PDF</>}
              />
              <Route
                exact
                path="/pdf-to-word"
                component={() => <>PDF to Word</>}
              />
              <Route
                exact
                path="/pdf-to-excel"
                component={() => <>PDF to Excel</>}
              />
              <Route
                exact
                path="/pdf-to-ppt"
                component={() => <>PDF to PPT</>}
              />
              <Route
                exact
                path="/pdf-to-jpeg"
                component={() => <>PDF to JPG</>}
              />
            </Switch>
          </button>
          <div className="dropdown-menu">
            <Link to="/word-to-pdf" className="p-1 dropdown-item word-to-pdf">
              Word to PDF
            </Link>
            <Link to="/excel-to-pdf" className="p-1 dropdown-item excel-to-pdf">
              Excel to PDF
            </Link>
            <Link to="/ppt-to-pdf" className="p-1 dropdown-item ppt-to-pdf">
              PPT to PDF
            </Link>
            <Link to="/jpeg-to-pdf" className="p-1 dropdown-item jpeg-to-pdf">
              JPG to PDF
            </Link>
            <div className="dropdown-divider"></div>
            <Link to="/pdf-to-word" className="p-1 dropdown-item pdf-to-word">
              PDF to Word
            </Link>
            <Link to="/pdf-to-excel" className="p-1 dropdown-item pdf-to-excel">
              PDF to Excel
            </Link>
            <Link to="/pdf-to-ppt" className="p-1 dropdown-item pdf-to-ppt">
              PDF to PPT
            </Link>
            <Link to="/pdf-to-jpeg" className="p-1 dropdown-item pdf-to-jpeg">
              PDF to JPG
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default TypeNavigation;
