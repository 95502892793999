import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class MetaChanger extends PureComponent {
  static defaultProps = {
    title: null,
    description: null,
  };
  constructor(props) {
    super(props);
    this.elementMetaDesc = document.querySelector('meta[name="description"]');
    this.element = document.getElementsByTagName('title')[0];
  }
  render() {
    this.element.innerText = this.props.title;
    this.elementMetaDesc.setAttribute('content', this.props.description);
    return null;
  }
}

MetaChanger.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default MetaChanger;
