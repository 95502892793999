import { useEffect, useState, useRef } from 'react';

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef(null);

  const handleScroll = () => {
    window.scrollY > 180 ? setSticky(true) : setSticky(false);
    if (document.querySelector('.ico-word')) {
      document.querySelector('.ico-word').style.transform =
        'translateY(' + (0 - window.scrollY * 0.35) + 'px)';
      document.querySelector('.ico-xls').style.transform =
        'translateY(' + (0 - window.scrollY * 0.3) + 'px)';
      document.querySelector('.ico-ppt').style.transform =
        'translateY(' + (0 - window.scrollY * 0.2) + 'px)';
      document.querySelector('.ico-pdf').style.transform =
        'translateY(' + (0 - window.scrollY * 0.15) + 'px)';
    }
  };

  const debounce = (func, wait = 1, immediate = true) => {
    let timeOut;
    return () => {
      let context = this,
        args = arguments;
      const later = () => {
        timeOut = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  useEffect(() => {
    window.addEventListener('scroll', debounce(handleScroll));
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [debounce, handleScroll]);

  return { isSticky, element };
}

export default useSticky;
