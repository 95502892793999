import React, { Component } from 'react';

class Terms extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return (
      <div className={'inner-text'}>
        <h3 className="mb-3">Terms of Use</h3>
        <div className={'container'}>
          <p className="mb-3">
            Last updated: <strong>March 2020</strong>
          </p>
          <p>
            PLEASE READ THE FOLLOWING CAREFULLY BEFORE INSTALLING THE APP AND/OR
            USING THE SERVICE.
          </p>
          <p>
            By clicking the “accept” or “ok” button, or installing and/or or by
            otherwise accessing or using any part of the App software (the “
            <strong>Software</strong>”) you expressly acknowledge and agree that
            you are entering into a legal agreement and have reviewed,
            understood and agree to comply with, and be legally bound by, the
            terms and conditions of this Terms of Use Agreement (“
            <strong>Agreement</strong>”). You hereby waive any applicable rights
            to require an original (non-electronic) signature or delivery or
            retention of non-electronic records, to the extent not prohibited
            under applicable law. If you do not agree to be bound by this
            Agreement please do not download, install or use the Software.
          </p>
          <p>
            <strong>1. Ability to Accept.</strong> By installing the Software
            you affirm that you are over thirteen (13) years of age. If you are
            between the age of thirteen (13) and eighteen (18) years then, prior
            to installing the Software, you must first review this Agreement
            with your parent or guardian to make sure that you and your parent
            or guardian understand its terms and conditions and agree to them.
          </p>
          <p>
            <strong>2. License.</strong> Subject to the terms and conditions of
            this Agreement, we hereby grant you a personal, revocable,
            non-exclusive, non- sublicensable, non-assignable, non-transferable
            license (“<strong>License</strong>”) to: (i) download and install
            the Software; (ii) use the Software on a device that you own or
            control (“<strong>Device</strong>”); and (ii) access and use the
            Software on that Device in accordance with this Agreement and any
            applicable Usage Rules (defined below).
          </p>
          <p>
            <strong>3. License Restrictions.</strong> You agree not to, and
            shall not permit any third party to: (i) sublicense, redistribute,
            sell, lease, lend or rent the Software; (ii) make the Software
            available over a network where it could be used by multiple devices
            owned or operated by different people at the same time; (iii)
            disassemble, reverse engineer, decompile, decrypt, or attempt to
            derive the source code of, the Software; (iv) copy (except for back-
            up purposes), modify, improve, or create derivative works of the
            Software, or any part thereof; (v) circumvent, disable or otherwise
            interfere with security-related features of the Software or features
            that prevent or restrict use or copying of any content or that
            enforce limitations on use of the Software; (vi) remove, alter or
            obscure any proprietary notice or identification, including
            copyright, trademark, patent or other notices, contained in or
            displayed on or via Software; (vii) use any communications systems
            provided by the Software to send unauthorized and/or unsolicited
            commercial communications; (viii) use the logo or trademarks without
            our prior written consent; and/or (ix) use the Software to violate
            any applicable laws, rules or regulations, or for any unlawful,
            harmful, irresponsible, or inappropriate purpose, or in any manner
            that breaches this Agreement.
          </p>
          <p>
            <strong>4. Usage Rules.</strong> If you are downloading the Software
            from a third party mobile device platform or service provider (“
            <strong>Distributor</strong>”), please be aware that the Distributor
            may have established usage rules which also govern your use of the
            Software (“<strong>Usage Rules</strong>”), depending on where the
            Software has been downloaded from. You acknowledge that, prior to
            downloading the Software from a Distributor, you have had the
            opportunity to review and understand, and will comply with, its
            Usage Rules. The Usage Rules that are applicable to your use of the
            Software are incorporated into this Agreement by this reference. You
            represent that you are not prohibited by any applicable Usage Rules
            and/or applicable law from using the Software; if you are unable to
            make such a representation you are prohibited from installing the
            Software.
          </p>
          <p>
            <strong>5. Payments.</strong>
          </p>
          <p>
            <strong>5.1.</strong> The License granted hereunder is currently for
            free, however, we may in the future charge a fee for certain
            features and/or uses. You will not be charged for any such uses of
            the Software unless you first agree to such charges, but please be
            aware that any failure to pay applicable charges may result in you
            not having access to some or all of the Software.
          </p>
          <p>
            <strong>5.2.</strong> Please be aware that your use of the Software
            may require and utilize internet connection or data access. To the
            extent that third party service provider or carrier charges for your
            internet or data usage are applicable, you agree to be solely
            responsible for those charges.
          </p>

          <p>
            <strong>6. Information Description.</strong> We attempt to be as
            accurate as possible. However, we cannot and do not warrant that the
            content available on the Software is accurate, complete, reliable,
            current, or error-free. We reserve the right to make changes in or
            to the content, or any part thereof without the requirement of
            giving you any notice prior to or after making such changes to the
            content.
          </p>
          <p>
            <strong>7. Third Party Sources and Content.</strong>
          </p>
          <p>
            <strong>7.1.</strong> The Software enables you to view, access, link
            to, and use content from Third Party Sources (defined below) that
            are not owned or controlled by us (“
            <strong>Third Party Content</strong>”). The Software may also enable
            you to communicate and interact with Third Party Sources. “
            <strong>Third Party Source(s)</strong>” means: (i) third party
            websites, applications and services; and (ii) our partners and
            customers.
          </p>
          <p>
            <strong>7.2.</strong> We are not affiliated with and have no control
            over any Third Party Sources. We do not assume any responsibility
            for the content, terms of use, privacy policies, actions or
            practices of, any Third Party Sources. Please read the terms of use
            and privacy policy of any Third Party Source that you interact with
            before you engage in any such activity.
          </p>
          <p>
            <strong>7.3.</strong> We are not responsible for, and we expressly
            disclaim all warranties regarding, the accuracy, appropriateness,
            usefulness, safety, or Intellectual Property Rights (defined below)
            of, or relating to, any Third Party Content.
          </p>
          <p>
            <strong>7.4.</strong> We do not endorse any advertising, promotions,
            campaigns, products, services or other materials that is included in
            any Third Party Content or that is communicated to you from a Third
            Party Source.
          </p>
          <p>
            <strong>7.5.</strong> By using the Software you may be exposed to
            Third Party Content that is inaccurate, offensive, indecent, or
            objectionable. You always have the choice of deciding whether or not
            to interact with a Third Party Source or to view and use Third Party
            Content. Your interaction with a Third Party Source and your use of,
            and reliance upon, any Third Party Content is at your sole
            discretion and risk.
          </p>
          <p>
            <strong>8. Third Party Open Source Software.</strong> Portions of
            the Software may include third party open source software that are
            subject to third party terms and conditions (“
            <strong>Third Party Terms</strong>”). A list of any third party open
            source software and related Third Party Terms,If there is a conflict
            between any Third Party Terms and the terms of this Agreement, then
            the Third Party Terms shall prevail but solely in connection with
            the related third party open source software. Notwithstanding
            anything in this Agreement to the contrary.
          </p>
          <p>
            <strong>9. Warranty Disclaimers.</strong>
          </p>
          <p>
            <strong>9.1.</strong> THE SOFTWARE IS PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND INCLUDING, WITHOUT
            LIMITATION, REPRESENTATIONS, WARRANTIES AND CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR FROM A COURSE OF
            DEALING OR USAGE OF TRADE.
          </p>
          <p>
            <strong>9.2.</strong> WE DO NOT WARRANT THAT THE SOFTWARE WILL
            OPERATE ERROR-FREE, THAT THE SOFTWARE ARE FREE OF VIRUSES OR OTHER
            HARMFUL CODE OR THAT WE WILL CORRECT ANY ERRORS IN THE SOFTWARE. YOU
            AGREE THAT WE WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES TO
            YOU OR ANY THIRD PARTY THAT MAY RESULT FROM TECHNICAL PROBLEMS
            INCLUDING WITHOUT LIMITATION IN CONNECTION WITH THE INTERNET (SUCH
            AS SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
            SERVERS) OR ANY TELECOMMUNICATIONS OR INTERNET PROVIDERS.
          </p>
          <p>
            <strong>9.3.</strong> IF YOU HAVE A DISPUTE WITH ANY OTHER SOFTWARE
            USER, YOU AGREE THAT WE ARE NOT LIABLE FOR ANY CLAIMS OR DAMAGES
            ARISING OUT OF OR CONNECTED WITH SUCH A DISPUTE. WE RESERVE THE
            RIGHT, BUT HAVE NO OBLIGATION, TO MONITOR ANY SUCH DISPUTE.
          </p>
          <p>
            <strong>9.4.</strong> Applicable law may not allow the exclusion of
            certain warranties, so to that extent such exclusions may not apply.
          </p>
          <p>
            <strong>10. Limitation of Liability.</strong>
          </p>
          <p>
            <strong>10.1.</strong> Use the Chrome extension &quot;SmartPDF&quot;
            at your own risk. No guarantee or warranty is given. The author
            shall not be held liable for any damage or loss of data caused by
            its use. Usage of &quot;SmartPDF&quot; means that you agree to these
            terms.
          </p>
          <p>
            <strong>10.2.</strong> The Chrome extension &quot;SmartPDF&quot; is
            have no affiliations with other popular PDF Converters. All
            copyrights belong to their respective owners.
          </p>
          <p>
            <strong>11. Export Laws.</strong> You agree to comply fully with all
            applicable export laws and regulations to ensure that neither the
            Software nor any technical data related thereto are exported or
            re-exported directly or indirectly in violation of, or used for any
            purposes prohibited by, such laws and regulations.
          </p>
          <p>
            <strong>12. Updates and Upgrades.</strong> We may from time to time
            provide updates or upgrades to the Software (each a “
            <strong>Revision</strong>”), but are not under any obligation to do
            so. Such Revisions will be supplied according to our then-current
            policies, which may include automatic updating or upgrading without
            any additional notice to you. You consent to any such automatic
            updating or upgrading of the Software. All references herein to the
            Software shall include Revisions. This Agreement shall govern any
            Revisions that replace or supplement the original Software, unless
            the Revision is accompanied by a separate license agreement which
            will govern the Revision.
          </p>
        </div>
      </div>
    );
  }
}

export default Terms;
