import React, { Component } from 'react';

class LoadingSpinner extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return <>Loading...</>;
  }
}

export default LoadingSpinner;
