import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import API from '../services/API';
import { success } from '../redux/modules/notifications';
import { update } from '../redux/modules/auth';

class AccountProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      isProcessing: false,
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.save = this.save.bind(this);
  }
  render() {
    return (
      <>
        <h3>User profile</h3>
        <p>Please fill all the fields correctly</p>
        <form
          onSubmit={this.save}
          className="pl-5"
          method="post"
          encType="multipart/form-data"
        >
          <div className="form-row">
            <div className="form-group col-md-6">
              <p>First name:</p>
              <input
                value={this.state.firstName}
                onChange={this.handleFirstNameChange}
                disabled={this.state.isProcessing}
                type="text"
                className="form-control"
                placeholder="First name"
                minLength="1"
                maxLength="255"
                required
              />
            </div>
            <div className="form-group col-md-6">
              <p>Last name:</p>
              <input
                value={this.state.lastName}
                onChange={this.handleLastNameChange}
                disabled={this.state.isProcessing}
                type="text"
                className="form-control"
                placeholder="Last name"
                minLength="1"
                maxLength="255"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <p>Email address:</p>
              <input
                value={this.state.email}
                onChange={this.handleEmailChange}
                disabled={this.state.isProcessing}
                type="email"
                className="form-control"
                placeholder="Email address"
                minLength="1"
                maxLength="255"
                required
              />
            </div>
          </div>
          <button
            onClick={this.save}
            type="button"
            className="btn btn-primary"
            disabled={this.state.isProcessing}
          >
            {this.state.isProcessing && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Save changes
          </button>
        </form>
      </>
    );
  }
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  save(e) {
    e.preventDefault();
    this.setState({ isProcessing: true });

    API.put(`/api/users/${this.props.id}/`, {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    })
      .then(({ data: user }) => {
        this.props.dispatch(success('The profile information is updated'));
        this.props.dispatch(update({ user }));
      })
      .catch(() => {})
      .finally(() => this.setState({ isProcessing: false }));
  }
}

AccountProfile.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  id: state.auth.user.id,
  email: state.auth.user.email,
  firstName: state.auth.user.firstName,
  lastName: state.auth.user.lastName,
}))(AccountProfile);
