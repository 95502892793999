import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormSpinner from './FormSpinner';
import API from '../services/API';
import { success } from '../redux/modules/notifications';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastName: '',
      firstName: '',
      email: '',
      message: '',
      isProcessing: false,
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
  }
  render() {
    return (
      <form
        onSubmit={this.sendFeedback}
        method="post"
        encType="multipart/form-data"
      >
        <div className="form-row">
          <div className="form-group col-md-6">
            <input
              value={this.state.firstName}
              onChange={this.handleFirstNameChange}
              disabled={this.state.isProcessing}
              type="text"
              className="form-control"
              name="name"
              placeholder="First name"
              required
            />
          </div>
          <div className="form-group col-md-6">
            <input
              value={this.state.lastName}
              onChange={this.handleLastNameChange}
              disabled={this.state.isProcessing}
              type="text"
              className="form-control"
              name="name"
              placeholder="Last name"
              required
            />
          </div>
          <div className="form-group col-md-12">
            <input
              value={this.state.email}
              onChange={this.handleEmailChange}
              disabled={this.state.isProcessing}
              type="email"
              className="form-control"
              name="email"
              placeholder="Email address"
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <textarea
              value={this.state.message}
              onChange={this.handleMessageChange}
              disabled={this.state.isProcessing}
              className="form-control"
              rows="7"
              name="message"
              placeholder="Please tell us your thoughts about Smart PDF. We are glad to hear from you!"
              required
            ></textarea>
          </div>
        </div>
        <FormSpinner shown={this.state.isProcessing} />
        <button
          type="submit"
          className="btn btn-primary"
          disabled={this.state.isProcessing}
        >
          Send message
        </button>
      </form>
    );
  }
  componentDidMount() {
    let initialState = {};

    if (this.props.user.firstName) {
      initialState = {
        ...initialState,
        firstName: this.props.user.firstName,
      };
    }

    if (this.props.user.lastName) {
      initialState = {
        ...initialState,
        lastName: this.props.user.lastName,
      };
    }

    if (this.props.user.email) {
      initialState = {
        ...initialState,
        email: this.props.user.email,
      };
    }

    this.setState(initialState);
  }
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }
  sendFeedback(e) {
    e.preventDefault();
    this.setState({ isProcessing: true });

    API.post('/api/feedbacks/', {
      name: `${this.state.firstName} ${this.state.lastName}`,
      email: this.state.email,
      message: this.state.message,
    })
      .then(() => {
        this.props.dispatch(success('Thank you for the feedback.'));
        this.setState({ message: '' });
      })
      .catch(() => {})
      .finally(() => this.setState({ isProcessing: false, message: '' }));
  }
}

Feedback.propTypes = {
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(
  connect(state => ({ user: state.auth.user || {} }))(Feedback)
);
