import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Promo1Img from '../assets/images/v2/sec_1_promo.svg';
import Promo2Img from '../assets/images/v2/sec_2_promo.svg';
import Promo3Img from '../assets/images/v2/sec_3_promo.svg';

class PromoSections extends Component {
  render() {
    return (
      <>
        <section className={'promo promo-1'}>
          <div className="container" id="pricing">
            <div className="image">
              <img src={Promo1Img} alt="Most Popular Formats Supported" />
            </div>
            <div className="placement">
              <h4>Most Popular Formats Supported</h4>
              <p>
                All you need in working with PDF Format are in one place. We
                support both converting in PDF and from PDF for free. Fast and
                quality conversion for documents and images during your work
                will simplify your everyday documents managing
              </p>
              <Link className="btn" to="/about">
                Learn more
              </Link>
            </div>
          </div>
        </section>
        <section className={'promo promo-2'}>
          <div className="container" id="pricing">
            <div className="placement">
              <h4>We Value Your Privacy</h4>
              <p>
                Working with official documents requires full security, thus
                Smart PDF Converter uses modern cryptography to protect your
                data. Advanced technology for keeping your documents in save is
                the number one point for us.
              </p>
              <Link className="btn" to="/privacy-policy">
                Learn more
              </Link>
            </div>
            <div className="image">
              {' '}
              <img src={Promo2Img} alt="We Value Your Privacy" />
            </div>
          </div>
        </section>
        <section className={'promo promo-3'}>
          <div className="container" id="pricing">
            <div className="image">
              <img src={Promo3Img} alt="Cloud Storage is available" />
            </div>
            <div className="placement">
              <h4>Cloud Storage is available</h4>
              <p>
                The premium subscription provides an additional service for
                keeping your files in a Smart PDF account. So you will have a
                folder where all files will be kept for further usage with the
                opportunity to download it.
              </p>
              <Link className="btn" to="/pricing">
                Learn more
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default connect()(PromoSections);
