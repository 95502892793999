import { createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from './modules/auth';
import notifications from './modules/notifications';
import promotions from './modules/promotions';

const persistedAuthReducer = persistReducer({ key: 'auth', storage }, auth);
const persistedPromotionsReducer = persistReducer(
  { key: 'promotions', storage },
  promotions
);

const initState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    auth: persistedAuthReducer,
    notifications,
    promotions: persistedPromotionsReducer,
  }),
  initState,
  composeEnhancers()
);

export const persistor = persistStore(store);

export default store;
