import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FormSpinner extends Component {
  render() {
    return (
      <>
        {this.props.shown && (
          <div className="spinner-overlay">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </>
    );
  }
}

FormSpinner.propTypes = {
  shown: PropTypes.bool.isRequired,
};

export default FormSpinner;
