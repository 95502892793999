import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Signin from './Signin';
import Signup from './Signup';
import ResetPasword from './ResetPasword';
import Converter from './Converter';
import FAQ from './FAQ';
import About from './About';
import Terms from './Terms';
import Privacy from './Privacy';
import ContactUs from './ContactUs';
import MainPage from './MainPage';
import Inner from './Inner';
import Account from './Account';
import Notification from './Notification';

class App extends Component {
  render() {
    return (
      <>
        <Inner>
          <Switch>
            <Route
              exact
              path="/word-to-pdf"
              component={() => <Converter type={'word-to-pdf'} />}
            />
            <Route
              exact
              path="/excel-to-pdf"
              component={() => <Converter type={'excel-to-pdf'} />}
            />
            <Route
              exact
              path="/ppt-to-pdf"
              component={() => <Converter type={'ppt-to-pdf'} />}
            />
            <Route
              exact
              path="/jpeg-to-pdf"
              component={() => <Converter type={'jpeg-to-pdf'} />}
            />
            <Route
              exact
              path="/pdf-to-word"
              component={() => <Converter type={'pdf-to-word'} />}
            />
            <Route
              exact
              path="/pdf-to-excel"
              component={() => <Converter type={'pdf-to-excel'} />}
            />
            <Route
              exact
              path="/pdf-to-ppt"
              component={() => <Converter type={'pdf-to-ppt'} />}
            />
            <Route
              exact
              path="/pdf-to-jpeg"
              component={() => <Converter type={'pdf-to-jpeg'} />}
            />
            <Route exact path="/feedback" component={ContactUs} />
            <Route exact path="/faq" component={() => <FAQ />} />
            <Route exact path="/about" component={About} />
            <Route exact path="/terms-of-service" component={Terms} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <PrivateRoute path="/account" component={Account} />
            <Route path="" component={MainPage} />
          </Switch>
          <Switch>
            <PublicRoute exact path="/signin" component={Signin} />
            <PublicRoute exact path="/signup" component={Signup} />
            <PublicRoute
              exact
              path="/reset-password"
              component={ResetPasword}
            />
          </Switch>
          <Notification />
        </Inner>
      </>
    );
  }
}

App.propTypes = {
  user: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  token: state.auth.token,
  user: state.auth.user,
}))(App);
