import React, { Component } from 'react';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import WandIcon from '../../assets/images/v2/wand.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class WordToPdfDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - Convert Word to PDF Simply'}
          description={
            'The most demanded Doc to PDF conversion is available. Just select the needed Doc file and convert to PDF for free'
          }
        />
        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Simply convert Word into PDF" />
            </p>
            <h4>Simply convert Word into PDF</h4>
            <p>
              Drop your Word file in the appropriate field on the Smart PDF
              website and press the convert button. Then you may download or
              review converted Doc to PDF file
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="Doc to PDF conversion is supported" />
            </p>
            <h4>Doc to PDF conversion is supported</h4>
            <p>
              Smart PDF supports many formats. The most demanded Doc to PDF
              conversion is available. Just select the needed Doc file and
              convert to PDF for free
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Docx to PDF easily" />
            </p>
            <h4>Docx to PDF easily</h4>
            <p>
              Here you can find the most accurate Docx to PDF file conversion.
              Moreover it takes a few seconds to convert Word to PDF file and
              download needed document
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Convert from Word to PDF Securely" />
            </p>
            <h4>Convert from Word to PDF Securely</h4>
            <p>
              We use modern cryptography and process all Word to PDF requests
              with advanced security certificates. We take care about the
              privacy of all uploaded documents
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="Word files storage" />
            </p>
            <h4>Word files storage</h4>
            <p>
              Smart PDF deletes all uploaded documents as well as all converted
              Doc to PDF files during an hour after the conversion. We don’t
              store your docs longer than one hour
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default WordToPdfDetails;
