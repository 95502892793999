import React, { Component } from 'react';
import { connect } from 'react-redux';
import FaqItem from './FaqItem';

class FaqSection extends Component {
  constructor(props) {
    super(props);
    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section className={'faq-wrapper'}>
        <h3>Questions & Answers</h3>
        <div className={'container'}>
          <ul>
            <FaqItem className={'active'} onToggle={this.onToggle}>
              <h4>How to convert a PDF file?</h4>
              <p className={'faq-content'}>
                It&apos;s very easy in using PDF converter service. Just follow
                the next steps:
              </p>
              <p className={'faq-content'}>
                <p>
                  {' '}
                  - Select needed format for converting. For your convenience we
                  divided available options into two columns. The first one
                  makes conversion to PDF format and the second converts from
                  PDF.
                </p>
                <p>
                  {' '}
                  - Drop the file in the appropriate box or choose from your PC.
                  Also there is an opportunity to select a file from DropBox.
                </p>
                <p>
                  {' '}
                  - Press the &quot;Convert&quot; button and the file will be
                  converted in a second.
                </p>
                <p>- Download directly to your PC or review it online.</p>
                Here you go!
              </p>
            </FaqItem>
            <FaqItem onToggle={this.onToggle}>
              <h4>What formats are supported?</h4>
              <p className={'faq-content'}>
                We provide a service that converts files from PDF format and to
                PDF.
              </p>
              <p>
                Word, Excel, PPT, JPG are supported. So you can easily convert:
              </p>
              <p>- Word to PDF</p>
              <p>- Excel to PDF</p>
              <p>- PPT to PDF</p>
              <p>- JPG to PDF</p>
              <p>As well as vise verse like:</p>
              <p>- PDF to Word</p>
              <p>- PDF to Excel</p>
              <p>- PDF to PPT</p>
              <p>- PDF to JPG</p>
            </FaqItem>
            <FaqItem onToggle={this.onToggle}>
              <h4>Can I convert PDF to Word?</h4>
              <p className={'faq-content'}>
                Yes, you can. With SmartPDF service you can easily convert PDF
                to Word. On the main page you can find columns that provide
                conversion to PDF and from PDF format. Just choose the option
                from the column &quot;Convert from PDF&quot; and follow needed
                steps to make conversion.
              </p>
              <p>
                To convert PDF to DOC online became simply, because we support
                both doc and docx formats. Just take file as it is and convert
                it online with an original quality.{' '}
              </p>
            </FaqItem>
            <FaqItem onToggle={this.onToggle}>
              <h4>Can I convert PDF to Excel?</h4>
              <p className={'faq-content'}>
                The same thing, you can easily convert a PDF document to Excel
                file online. All platforms supported. Drop a file from Windows,
                Mac or Linux devices and convert PDF to Excel in seconds. Smart
                PDF service recognize both formats, so either PDF to xls or PDF
                to XLSX will be converted online in a moment. Be sure the
                document will look exactly as original one, so you will be able
                to work with it further.
              </p>
            </FaqItem>
            <FaqItem onToggle={this.onToggle}>
              <h4>Are my converted files secured?</h4>
              <p className={'faq-content'}>
                Smart PDF guarantees privacy and security of all documents you
                upload for converting. Our server does not store files and
                delete them once you close the window. We take care about
                anonymously and safety of your official documents. Be sure that
                all data that you convert to PDF or convert from PDF will be
                secured.
              </p>
            </FaqItem>
            <FaqItem onToggle={this.onToggle}>
              <h4>How much time PDF converting takes?</h4>
              <p className={'faq-content'}>
                We use modern technology that provides fast and smooth
                converting files to PDF format and from PDF. All operations,
                will it be Word to PDF or PDF to Excel, or any other conversion
                PDF format will take just seconds. Convert all formats quickly
                and on-the-job. PPT to PDF, PDF to JPG and vise verse all these
                provides one Smart PDF service.
              </p>
            </FaqItem>
            <FaqItem onToggle={this.onToggle}>
              <h4>Where can I find converted files after downloading?</h4>
              <p className={'faq-content'}>
                When you convert a needed format of documents you will be
                provided with two options: view or download PDF, DOC or other
                file. Downloaded doc will be saved in your default folder,
                usually it is &quot;Downloads&quot;. Smart PDF converter is very
                easy and intuitive in using and converts DOC, XLXS, PPT, JPG
                formats in a moment.
              </p>
            </FaqItem>
          </ul>
        </div>
      </section>
    );
  }

  onToggle(el) {
    if (this.el) {
      this.el.classList.remove('active');
    }

    this.el = el;
  }
}

export default connect()(FaqSection);
