import React, { Component } from 'react';
import MetaChanger from './MetaChanger';
import Feedback from './Feedback';

class ContactUs extends Component {
  shouldComponentUpdate() {
    return false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className={'inner-text contacts'}>
        <MetaChanger
          title={'Smart PDF - Free PDF Converter Tool'}
          description={
            'Free online PDF Converter for Word, Excel, PPT and JPG. Easily convert from PDF and to PDF with a high quality.'
          }
        />
        <h3 className="mb-3">Contact us</h3>
        <div className={'container'}>
          <div className={'col-md-12 d-flex'}>
            <div className={'col-md-6'}>
              <Feedback />
            </div>
            <div className={'col-md-6'}>
              <div>
                <h4>Have a question? Let us know!</h4>
                <p>
                  Contact us via a short form to share your feedback or get help
                  with any issue. We appreciate all your comments and glad to
                  help with a service usage.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
