import React, { Component } from 'react';

class Privacy extends Component {
  shouldComponentUpdate() {
    return false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className={'inner-text'}>
        <h3 className="mb-3">Privacy Policy</h3>
        <div className={'container'}>
          <p>
            EFFECTIVE DATE: <strong>February 18, 2019</strong>
          </p>
          <h4>1. Introduction</h4>
          <p>
            This Privacy Policy sets forth the privacy practices with respect to
            all information, data, graphics, content, software applications; the
            Smart PDF website, located at&nbsp;https://smartpdf.org, (the
            “Website”); and other Smart PDF products or services provided or
            otherwise made accessible on or through the Software or the Website.
            Collectively, the Software, the Website, and any other Smart PDF
            services are hereinafter referred to as the “Service”. In this
            Privacy Policy, “we”, “us”, “our” and other similar references mean
            Smart PDF and “you” and “your” and other similar references mean any
            particular user of the Service. This Privacy Policy describes how
            Smart PDF uses personal information of users collected through our
            Service (“Personal Information”).
          </p>
          <p>
            This policy applies (i) immediately to users who utilize the Service
            on or after the Effective Date and (ii) on the Effective Date to
            users who utilize the Service before the Effective Date. Any person
            who does not agree to be bound by this Privacy Policy should not
            utilize the Service. Each capitalized term not otherwise defined
            herein shall have the meaning set forth in Section 2.
          </p>
          <p>
            Please contact us if you have any questions or comments about our
            privacy practices or this Privacy Policy. You can reach us online
            at&nbsp;hello@smartpdf.org, or by mail at:
          </p>
          <p>
            PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY ACCESSING OR USING THE
            SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE
            TO BE BOUND TO ALL THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY
            AND OUR TERMS OF USE. IF YOU DO NOT AGREE TO THESE TERMS AND
            CONDITIONS, PLEASE EXIT, AND DO NOT ACCESS OR USE, THE SERVICE.
          </p>
          <h4>2. Definitions</h4>
          <p>
            a) “Contact Information” means (i) your name, (ii) your physical
            address, (iii) your email address and (iv) your telephone
            number.&nbsp;
            <br />
            b) “Effective Date” means the date set forth at the beginning of
            this document.&nbsp;
            <br />
            c) “Smart PDF” means any parent, subsidiary, member, officer,
            director, employee, or agent of Smart PDF or any company under
            common control with Smart PDF.&nbsp;
            <br />
            d) “Privacy Policy” means the set of policies set forth in this
            document.&nbsp;
            <br />
            g) “User Account” means an account permitting access to the Service,
            with which is associated a user name and password.&nbsp;
            <br />
            h) “User Account Information” means information that identifies you
            to the Service, including your user name, password, internet
            protocol address, and MAC address.&nbsp;
            <br />
            i) “User Content” means images, comments, and other materials that
            you post to the Service.
          </p>
          <h4>3. Types of Personal Information We Collect</h4>
          <p>
            Smart PDF collects the following types of Personal Information
            through the Service:
          </p>
          <p>
            a) Contact Information;&nbsp;
            <br />
            b) User Account Information;
          </p>
          <h4>4. Mechanisms for Collection of Personal Information</h4>
          <p>
            Smart PDF collects Personal Information (i) upon a user’s creation
            of a User Account, (ii) upon logging into the Service, (iii) upon
            interactions with the Service, and (iv) upon uploading User Content
            to, or generating User Content on, the Service, and (v) when you
            communicate with Smart PDF through the Service. When you access our
            Service, we may place small data files on your computer or other
            device known as “cookies”. We use cookies to recognize you as a user
            of the Service. Cookies may remain on your device until you erase
            them or they expire. You are free to decline our cookies if your
            browser or browser add-on permits, but doing so may interfere with
            your use of the Service. You should refer to the help sections of
            your browser, browser extensions, or installed applications for
            instructions on blocking, deleting, or disabling cookies.
          </p>
          <h4>5. Purpose of Collection and Use of Personal Information</h4>
          <p>Smart PDF uses Personal Information to:</p>
          <ul>
            <li>operate and improve the Service;</li>
            <li>target offers to users;</li>
            <li>
              administer the Service’s facilities for communications among and
              between users and the public in general;
            </li>
            <li>
              evaluate eligibility of customers for certain offers, products or
              services;
            </li>
            <li>
              evaluate the types of offers, products or services that may be of
              interest to users;
            </li>
            <li>provide user support;</li>
            <li>
              communicate with users regarding support, security, technical
              issues, commerce, marketing, and transactions;
            </li>
            <li>
              facilitate marketing, advertising, surveys, contests, sweepstakes
              and promotions;
            </li>
            <li>
              administer the Service, User Accounts and transactions with
              respect to User Accounts;
            </li>
            <li>
              administer and carry out our obligations under contracts and to
              comply with the law.
            </li>
          </ul>
          <h4>6. Non-personal identification information</h4>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our service. Non-personal identification
            information may include (but not limited to) the browser name, the
            type of computer and technical information about Users means of
            connection to our Site, such as the operating system and the
            Internet service providers utilized and other similar information.
          </p>
          <h4>7. Storage and Security</h4>
          <p>
            We believe the security of your information is a serious issue and
            we are committed to protecting the information we receive from you.
            We use commercially reasonable security measures to protect against
            the loss, misuse and alteration of your information under our
            control. Notwithstanding such measures, please be aware that no
            security measures are perfect or impenetrable, and we cannot
            guarantee the security of any information transmitted to or from the
            Service. We also are not responsible for the actions of any third
            parties that may receive any such information.
          </p>
          <p>
            We may transfer your information to, or maintain it on, computers
            located outside of your country, in a location having laws that are
            less protective of your privacy.
          </p>
          <p>
            We will retain Personal Information only as long as our business
            needs require. We will then destroy or render unreadable any such
            information upon disposal.
          </p>
          <h4>8. E-mail Policies</h4>
          <p>
            Smart PDF reserves the right to send you e-mail relating to your
            account and to the Service. You cannot opt-out of receiving such
            e-mails, and you agree to receive them.
          </p>
          <h4>9. No Responsibility for Privacy Practices of Third Parties</h4>
          <p>
            This Privacy Policy applies only to the Service. It does not apply
            to sites that have linked or are redirected to or from the Service
            (“Third Party Sites”), regardless of whether or not such link or
            redirection is authorized by Smart PDF. Third Party Sites may have
            their own policies regarding privacy, or no policy at all. Smart PDF
            is not responsible for Third Party Sites and you use them at your
            own risk.
          </p>
          <h4>10. Interactions With Others</h4>
          <p>
            The Service may contain areas where you may be able to publicly post
            information, communicate with others such as discussion boards or
            blogs, review goods, services vendors, and submit media content.
            Such use is subject to our Terms of Service, and may be viewed by
            the public at large. Any information, including Personal
            Information, that you post there will be public, and therefore
            anyone who accesses such postings will have the ability to read,
            collect and further disseminate such information.
          </p>
          <h4>11. Changes to the Privacy Policy</h4>
          <p>
            Smart PDF reserves the right to change this Privacy Policy. Such
            changes may be announced to you either by email or by the posting of
            the revised policy on the Service. You are responsible for reviewing
            both your own email and the Service to determine what is the
            then-current Privacy Policy.
          </p>
        </div>
      </div>
    );
  }
}

export default Privacy;
