export const RESET_POPUP_CYCLE_START_AT =
  'promotions/RESET_POPUP_CYCLE_START_AT';

const CYCLE_LENGTH = /*60 * */ 60 * 1000;
const initialState = { popupCycleStartAt: Date.now() };

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_POPUP_CYCLE_START_AT:
      return { popupCycleStartAt: Date.now() + CYCLE_LENGTH };
    default:
      return state;
  }
}

export const recyclePromotion = () => ({ type: RESET_POPUP_CYCLE_START_AT });
