import React, { Component } from 'react';
import PromoSections from './PromoSections';
import Converter from './Converter';
import FaqSection from './FaqSection';
import InstallSection from './InstallSection';
import AdSenseBanner from './AdSenseBanner';
// import PremiumBanner from './PremiumBanner';

class MainPage extends Component {
  render() {
    return (
      <>
        {/* <PremiumBanner /> */}
        <Converter ismain={true} />
        <AdSenseBanner />
        <PromoSections />
        <FaqSection />
        <InstallSection />
      </>
    );
  }
}

export default MainPage;
