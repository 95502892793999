export const SET_TOKEN = 'auth/SET_TOKEN';
export const RESET_TOKEN = 'auth/RESET_TOKEN';
export const UPDATE_USER = 'auth/UPDATE_USER';

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
        user: action.user,
      };
    case RESET_TOKEN:
      return initialState;
    default:
      return state;
  }
}

export const signin = ({ token, user }) => ({ type: SET_TOKEN, token, user });
export const signout = () => ({ type: RESET_TOKEN });
export const update = ({ user }) => ({ type: UPDATE_USER, user });
