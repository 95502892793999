import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AdSenseBanner extends Component {
  componentDidMount() {
    if (this.props.show) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
  render() {
    if (this.props.show) {
      return (
        <ins
          className={'adsbygoogle'}
          style={{
            display: 'inline-block',
            width: '728px',
            height: '90px',
          }}
          data-ad-client="ca-pub-8798419843428583"
          data-ad-slot="1028662947"
        />
      );
    }

    return null;
  }
}
AdSenseBanner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default connect(state => {
  let show = true;

  if (state.auth.user) {
    if (state.auth.user.subscription) {
      if (state.auth.user.subscription.status === 'active') {
        show = false;
      }
    }
  }

  return { show };
})(AdSenseBanner);
