import axios from 'axios';
import store from '../redux';
import { error } from '../redux/modules/notifications';
import { signout } from '../redux/modules/auth';

const client = axios.create({
  responseType: 'json',
});

client.interceptors.request.use(
  config => {
    const { auth } = store.getState();
    const { token } = auth;

    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

client.interceptors.response.use(
  response => {
    return response;
  },
  ({ response = {} }) => {
    const { status } = response;
    const { data } = response;
    const { message } = { message: 'Something went wrong', ...data };

    if (status === 401) {
      store.dispatch(signout());
    } else {
      store.dispatch(error(message));
    }

    return Promise.reject(error);
  }
);

export default client;
