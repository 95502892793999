import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TypeNavigation from '../TypeNavigation';

class ConversionHandleSuccess extends Component {
  render() {
    return (
      <div className="view">
        <TypeNavigation />
        <div className="p-5 view-body">
          <div className="col-md-4 offset-4">
            <div className="d-flex flex-column">
              <p className="mb-3">The file has been successfully converted!</p>
              <a
                href={`/api/tasks/${this.props.task.id}/result?download=true`}
                className="p-2 mb-2 btn btn-download"
                target="_blank"
                rel="noreferrer"
                download
              >
                <img src="/image/download_icon.svg" alt="" /> Download File
              </a>
              <a
                href={`/api/tasks/${this.props.task.id}/result`}
                className="p-2 mb-2 btn btn-view"
                target="_blank"
                rel="noreferrer"
              >
                <img src="/image/view_icon.svg" alt="" /> View File
              </a>
              <button
                onClick={this.props.startNew}
                className="p-2 mb-2 btn btn-view"
              >
                <img src="/image/start_new__icon.svg" alt="" /> Start New
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConversionHandleSuccess.propTypes = {
  startNew: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

export default ConversionHandleSuccess;
