import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import ConversionOptions from './ConversionOptions';

function Inner(props) {
  const { children } = props;
  return (
    <>
      <Header />
      <section className={'page'}>{children}</section>
      <ConversionOptions />
      <Footer />
    </>
  );
}

Inner.propTypes = {
  children: PropTypes.array,
};

export default Inner;
