import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Signout from './Signout';
import API from '../services/API';
import { update } from '../redux/modules/auth';

class UserNavigation extends Component {
  componentDidMount() {
    if (this.props.user) {
      const { id } = this.props.user;
      API.get(`/api/users/${id}`)
        .then(({ data: user }) => this.props.dispatch(update({ user })))
        .catch(() => {});
    }
  }
  render() {
    return (
      <>
        {this.props.user ? (
          <div className="user-nav dropdown d-flex flex-column">
            <button
              className="btn btn-select dropdown-toggle col-md-12"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>{this.props.user.lastName.slice(0, 1)}</span>
              <i>{this.props.user.firstName}</i>
            </button>
            <div
              className="col-md-12 dropdown-menu"
              aria-labelledby="select-origin-menu"
            >
              <Link to="/account/profile" className="p-2 dropdown-item">
                Account
              </Link>
              <Signout />
            </div>
          </div>
        ) : (
          <Link to="/signin" className="sign-in p-2 pl-3 pr-3 btn">
            Sign In
          </Link>
        )}
      </>
    );
  }
}

UserNavigation.propTypes = {
  user: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({
  user: state.auth.user,
}))(UserNavigation);
