import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/v2/logo.png';
import Navigation from './Navigation';
import useSticky from './hooks/UseSticky';

const Header = () => (
  <div
    className={
      useSticky().isSticky ? 'smartpdf-header navbar-sticky' : 'smartpdf-header'
    }
  >
    <header
      role="navigation"
      className="navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar"
    >
      <div className="container flex-column flex-md-row">
        <h1 className="mr-0 mr-md-2 logo">
          <Link to="/">
            <img className={'main-logo'} src={Logo} title="" alt="SmartPDF" />
          </Link>
        </h1>
        <Navigation />
      </div>
    </header>
  </div>
);

export default Header;
