import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class JpegToPdfDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - PDF Converter for JPG Format'}
          description={
            'It’s an online converter that supports conversion from JPG to PDF as well as JPEG to PDF for free and without registration.'
          }
        />

        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Conversion became simple" />
            </p>
            <h4>Conversion became simple</h4>
            <p>
              With Smart PDF converter it’s become very simple to create a PDF
              file from a JPG image and vice versa
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="JPEG to PDF in seconds" />
            </p>
            <h4>JPEG to PDF in seconds</h4>
            <p>
              Create converted JPG image into PDF file easily and quickly. All
              you need is to upload the selected image and press the convert
              button to receive requested format
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Just drop file to make conversion" />
            </p>
            <h4>Just drop file to make conversion</h4>
            <p>
              Smart PDF converter supports conversion from JPG to PDF as well as
              JPEG to PDF for free and without registration. We made our quality
              service for you
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Privacy is guaranteed for PDF files" />
            </p>
            <h4>Privacy is guaranteed for PDF files</h4>
            <p>
              We take care about your privacy and ensure the most modern
              cryptography for all JPG to PDF Conversion for everyone according
              to our Privacy Policy
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="All operating system are supported" />
            </p>
            <h4>All operating system are supported</h4>
            <p>
              Use Windows, Linux or Mac OS to convert JPG image to PDF file for
              free spending just a few seconds. Get Microsoft JPEG has never
              been so easy
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default JpegToPdfDetails;
