import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ConversionChooseType extends Component {
  render() {
    return (
      <div className="view">
        <p className={'choose-type'}>
          Choose the file type that you want to convert:
        </p>
        <p className={'mobile-type'}>Convert to PDF:</p>
        <div className="d-flex flex-row col-md-10 m-auto">
          <div className="d-flex flex-column col-md-6">
            <Link
              to="/word-to-pdf"
              className="m-2 p-2 btn btn-word word-to-pdf"
            >
              Word to PDF
            </Link>
            <Link
              to="/excel-to-pdf"
              className="m-2 p-2 btn btn-excel excel-to-pdf"
            >
              Excel to PDF
            </Link>
            <Link
              to="/ppt-to-pdf"
              className="m-2 p-2 btn btn-powerpoint ppt-to-pdf"
            >
              PPT to PDF
            </Link>
            <Link
              to="/jpeg-to-pdf"
              className="m-2 p-2 btn btn-jpeg jpeg-to-pdf"
            >
              JPG to PDF
            </Link>
          </div>
          <div className="d-flex flex-column col-md-6">
            <p className={'mobile-type'}>Convert from PDF:</p>
            <Link
              to="/pdf-to-word"
              className="m-2 p-2 btn btn-word pdf-to-word"
            >
              PDF to Word
            </Link>
            <Link
              to="/pdf-to-excel"
              className="m-2 p-2 btn btn-excel btn-disabled pdf-to-excel"
              disabled
            >
              PDF to Excel
            </Link>
            <Link
              to="/pdf-to-ppt"
              className="m-2 p-2 btn btn-powerpoint pdf-to-ppt"
            >
              PDF to PPT
            </Link>
            <Link
              to="/pdf-to-jpeg"
              className="m-2 p-2 btn btn-jpeg pdf-to-jpeg"
            >
              PDF to JPG
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ConversionChooseType;
