const PUSH = 'notifications/PUSH';
const SHIFT = 'notification/SHIFT';

const initialState = [];

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case PUSH:
      return [
        ...state.filter(
          notification =>
            !(
              notification.level === action.level &&
              notification.message === action.message
            )
        ),
        { level: action.level, message: action.message },
      ];
    case SHIFT:
      return [...state.slice(1)];
    default:
      return state;
  }
}

export const success = message => ({ type: PUSH, level: 'success', message });
export const warn = message => ({ type: PUSH, level: 'warning', message });
export const error = message => ({ type: PUSH, level: 'danger', message });
export const info = message => ({ type: PUSH, level: 'info', message });
export const revoke = () => ({ type: SHIFT });

export const all = () => {};
