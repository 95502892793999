import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class PdfToJpegDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - PDF Converter for JPEG Format'}
          description={
            'Accurately conversion from PDF file to JPG for free. Save the time and promptly get PDF to JPEG.'
          }
        />
        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Exact Microsoft JPG from PDF file" />
            </p>
            <h4>Exact Microsoft JPG from PDF file</h4>
            <p>
              Accurately conversion from PDF file to JPG is possible with Smart
              PDF converter. Save the time and promptly get PDF to JPEG
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="Useful PDF converter tool" />
            </p>
            <h4>Useful PDF converter tool</h4>
            <p>
              Transform a PDF file into JPG image in a high quality within
              seconds. Extract a JPG image from a PDF file has never been so
              easy
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Constantly turn PDF into JPG" />
            </p>
            <h4>Constantly turn PDF into JPG</h4>
            <p>
              Convert PDF to JPG without any limits and for free. Smart PDF
              converter doesn’t require registrations or subscriptions
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Safety using for everyone" />
            </p>
            <h4>Safety using for everyone</h4>
            <p>
              Trust us any documents, as we don’t store them for more than an
              hour. All converted PDF to JPG image files are deleted during an
              hour. For more information please read our Privacy Policy
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="Various OS are suitable" />
            </p>
            <h4>Various OS are suitable</h4>
            <p>
              All operating systems are supported by Smart PDF converter and
              allows you to convert PDF to JPEG image accurately and for free
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default PdfToJpegDetails;
