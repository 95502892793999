import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormSpinner from './FormSpinner';
import API from '../services/API';
import { success } from '../redux/modules/notifications';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isProcessing: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.modal = createRef();
  }
  render() {
    return (
      <>
        <div
          ref={this.modal}
          className="modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form
                onSubmit={this.resetPassword}
                method="post"
                encType="multipart/form-data"
              >
                <div className="modal-header">
                  <h5 className="modal-title">Reset your password</h5>
                  <Link
                    to="/"
                    className="close"
                    disabled={this.state.isProcessing}
                  >
                    <span aria-hidden="true">&times;</span>
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        disabled={this.state.isProcessing}
                        type="email"
                        placeholder="Email address"
                        className="form-control"
                        minLength="3"
                        maxLength="255"
                        required
                      />
                    </div>
                  </div>
                  <FormSpinner shown={this.state.isProcessing} />
                </div>
                <div className="modal-footer">
                  <Link
                    to="/signin"
                    className="btn btn-sm btn-link"
                    disabled={this.state.isProcessing}
                  >
                    I remember the password
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.isProcessing}
                  >
                    Send recovery link
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    window.$(this.modal.current).modal('show');
  }
  componentWillUnmount() {
    window.$(this.modal.current).modal('hide');
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  resetPassword(e) {
    e.preventDefault();
    this.setState({ isProcessing: true });

    API.post(`/api/reset-password-requests/`, { email: this.state.email })
      .then(() => {
        this.props.dispatch(
          success('Check up your mailbox and follow instructions.')
        );
        this.props.history.push('/signin');
      })
      .catch(() => {})
      .finally(() => this.setState({ isProcessing: false }));
  }
}

ResetPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(ResetPassword));
