import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class PdfToWordDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - Convert PDF to Word Simply'}
          description={
            'Convert PDF to Doc with a high quality. Smart PDF is an efficient service that converts PDF to Word and makes your work easy.'
          }
        />

        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Convert PDF to Word in a few seconds" />
            </p>
            <h4>Convert PDF to Word in a few seconds</h4>
            <p>
              Upload your PDF file to the box and start the PDF to Word
              conversion that takes a few seconds. It’s fast and easy to use a
              PDF converter that you will love
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="PDF into Doc in a good quality" />
            </p>
            <h4>PDF into Doc in a good quality</h4>
            <p>
              Convert PDF to Microsoft Word Doc with high quality of converting.
              It’s an efficient service that converts PDF to Word and makes your
              work easy
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="All platforms are supported" />
            </p>
            <h4>All platforms are supported</h4>
            <p>
              Easily convert from PDF to Word file using Mac OS, Linux or
              Windows. Smart PDF Converter is good for converting from PDF to
              Doc with any platform
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Keep the data safe" />
            </p>
            <h4>Keep the data safe</h4>
            <p>
              We use modern cryptography to protect all your documents while you
              convert PDF to Word docs. The data is fully secured with the
              advanced security certificate
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="PDF to Docx converter" />
            </p>
            <h4>PDF to Docx converter</h4>
            <p>
              Your conversion from PDF file to Docs will take just seconds to
              create the requested Microsoft office Word document
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default PdfToWordDetails;
