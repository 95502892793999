import React, { Component } from 'react';
import MetaChanger from './MetaChanger';

class About extends Component {
  shouldComponentUpdate() {
    return false;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className={'inner-text'}>
        <MetaChanger
          title={'Smart PDF - Free PDF Converter Tool'}
          description={
            'Free online PDF Converter for Word, Excel, PPT and JPG. Easily convert from PDF and to PDF with a high quality.'
          }
        />
        <h3 className="mb-3">About Us</h3>
        <div className={'container'}>
          <p>
            <strong>Smart PDF converter</strong> is an online service that does
            conversions of most popular formats. Behind the bright front of a
            website there is a team of yaung and ambitious guys, who make all
            their efforts to provide the best quality in online PDF conversion
            services. We’ve developed a simple and clear PDF converter that
            supports different formats, among which converting Word, Excel, PPT,
            JPG to PDF and from PDF formats.
          </p>
          <p>
            Launched in 2017 as a team of freelance developers, we have grown to
            a strong crew with common thoughts and desires. We know exactly who
            we are and what we’d like to move into the user base. You will also
            know us and I hope you’ll become a part of our PDF community for
            years.
          </p>
          <p>
            A small team who’s grown in a web developing corporation knows
            exactly how important to have a solution for converting files into
            different formats near at hand for everyday use. We gather feedback
            from lots of specialists which formats more in demand. Therefore you
            can see most actual options on the platform without excessive
            pilling that makes its usage simple and convenient for everyone.
          </p>
          <p>
            In order to make using the PDF service most productive, we created a
            Chrome browser extension that will be an irreplaceable helper during
            your office or homework. Our product will be loved by everyone. Will
            it be an accountant from the finance department, a student of the
            high school or your neighborhood who wants to send an order to a
            building company. We are all using PDF converter from time to time.
            Let’s do it together!
          </p>
          <p>
            Since we are always opened for new offers and try to take care of
            every one of our users, we’d be glad to receive feedback, requests
            and suggestions. Feel free to contact us with any ideas.
          </p>
        </div>
      </div>
    );
  }
}

export default About;
