import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class PdfToPptDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - PDF to PPT Online Converter'}
          description={
            'Simply convert the PDF file to Powerpoint Presentation quickly and for free. Making a PPT slide from a PDF file is so easy with a free PDF Converter'
          }
        />

        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Quality PDF to PPT conversion" />
            </p>
            <h4>Quality PDF to PPT conversion</h4>
            <p>
              If you need to turn a PDF file into a PPT presentation, then you
              are in the right place. Just upload your file and convert PDF to
              PPT slide quickly with a high quality
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="Easy in use interface" />
            </p>
            <h4>Easy in use interface</h4>
            <p>
              We are oriented for a wide range of users, thus the interface of a
              PDF converter is so friendly and useful. Turn from PDF to PPTX
              with pleasure
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Fast and simple PDF converter" />
            </p>
            <h4>Fast and simple PDF converter</h4>
            <p>
              Convert PDF to PPT presentation to formatting, review and edit
              slides. Making a PPT slide from a PDF file is so easy with a free
              PDF Converter
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="We guarantee the privacy" />
            </p>
            <h4>We guarantee the privacy</h4>
            <p>
              The Smart PDF converter permanently deletes all converted and
              uploaded files during an hour. Secure PDF into Microsoft PPT
              conversion is ensured
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="Different OS to create PPT from PDF" />
            </p>
            <h4>Different OS to create PPT from PDF</h4>
            <p>
              Instantly converting from PDF to PPT presentation works with
              various operating systems. We support all platforms and devices
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default PdfToPptDetails;
