import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { signout } from '../redux/modules/auth';

class Signout extends Component {
  constructor(props) {
    super(props);
    this.signout = this.signout.bind(this);
  }
  render() {
    return (
      <button className="btn p-2 dropdown-item" onClick={this.signout}>
        Sign Out
      </button>
    );
  }
  signout() {
    this.props.dispatch(signout());
    this.props.history.push('/');
  }
}

Signout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(Signout));
