import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import API from '../../services/API';
import TypeNavigation from '../TypeNavigation';
import { GOOGLE_API_KEY } from '../../services/Config';
import { GOOGLE_CLIENT_ID } from '../../services/Config';

const TASK_ORIGIN_UPLOAD_FILE = 'file-upload';
const TASK_ORIGIN_GOOGLE_DRIVE = 'google-drive';

const TASK_TYPE_WORD_TO_PDF = 'word-to-pdf';
const TASK_TYPE_EXCEL_TO_PDF = 'excel-to-pdf';
const TASK_TYPE_PPT_TO_PDF = 'ppt-to-pdf';
const TASK_TYPE_JPEG_TO_PDF = 'jpeg-to-pdf';
const TASK_TYPE_PDF_TO_WORD = 'pdf-to-word';
const TASK_TYPE_PDF_TO_EXCEL = 'pdf-to-excel';
const TASK_TYPE_PDF_TO_PPT = 'pdf-to-ppt';
const TASK_TYPE_PDF_TO_JPEG = 'pdf-to-jpeg';

const MIME_TYPE = {
  [TASK_TYPE_WORD_TO_PDF]:
    'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.google-apps.document',
  [TASK_TYPE_EXCEL_TO_PDF]:
    'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.google-apps.spreadsheet',
  [TASK_TYPE_PPT_TO_PDF]:
    'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.google-apps.presentation',
  [TASK_TYPE_JPEG_TO_PDF]: 'image/jpeg,image/pjpeg',
  [TASK_TYPE_PDF_TO_WORD]: 'application/pdf',
  [TASK_TYPE_PDF_TO_EXCEL]: 'application/pdf',
  [TASK_TYPE_PDF_TO_PPT]: 'application/pdf',
  [TASK_TYPE_PDF_TO_JPEG]: 'application/pdf',
};
const resolveMimeType = type => MIME_TYPE[type];

const GOOGLE_FILE_TYPE = {
  [TASK_TYPE_WORD_TO_PDF]: 'documents',
  [TASK_TYPE_EXCEL_TO_PDF]: 'spreadsheets',
  [TASK_TYPE_PPT_TO_PDF]: 'presentations',
  [TASK_TYPE_JPEG_TO_PDF]: 'docs-images',
  [TASK_TYPE_PDF_TO_WORD]: 'pdfs',
  [TASK_TYPE_PDF_TO_EXCEL]: 'pdfs',
  [TASK_TYPE_PDF_TO_PPT]: 'pdfs',
  [TASK_TYPE_PDF_TO_JPEG]: 'pdfs',
};
const resolveGoogleFileType = type => GOOGLE_FILE_TYPE[type];

class ConversionPickSource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inDragging: false,
    };
    this.handleChosenFile = this.handleChosenFile.bind(this);
    this.handleDroppedFile = this.handleDroppedFile.bind(this);
    this.chooseFileFromFilesystem = this.chooseFileFromFilesystem.bind(this);
    this.chooseFileFromGoogleDrive = this.chooseFileFromGoogleDrive.bind(this);
  }
  render() {
    return (
      <div className="view upload-file-view">
        <TypeNavigation />
        <form
          ref={form => (this.formElement = form)}
          className={this.state.inDragging ? 'drag' : ''}
          onDrag={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragStart={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onDragOver={e => {
            e.preventDefault();
            e.stopPropagation();
            this.state.inDragging || this.setState({ inDragging: true });
          }}
          onDragEnter={e => {
            e.preventDefault();
            e.stopPropagation();
            this.state.inDragging || this.setState({ inDragging: true });
          }}
          onDragLeave={e => {
            e.preventDefault();
            e.stopPropagation();
            this.state.inDragging && this.setState({ inDragging: false });
          }}
          onDragEnd={e => {
            e.preventDefault();
            e.stopPropagation();
            this.state.inDragging && this.setState({ inDragging: false });
          }}
          onDrop={this.handleDroppedFile}
          method="post"
          encType="multipart/form-data"
        >
          <div className="p-5 view-body">
            <input
              ref={input => (this.fileInputElement = input)}
              onChange={this.handleChosenFile}
              type="file"
              accept={resolveMimeType(this.props.type)}
              name="source"
              className="d-none"
            />
            <div className="col-md-4 offset-4">
              <div className="dropdown d-flex flex-column mb-3">
                <button
                  className="btn btn-select select-file dropdown-toggle col-md-12"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Select a file
                </button>
                <div
                  className="col-md-12 dropdown-menu"
                  aria-labelledby="select-origin-menu"
                >
                  <button
                    onClick={this.chooseFileFromFilesystem}
                    type="button"
                    className="btn p-2 dropdown-item"
                  >
                    <img src="/image/pc_icon.svg" alt="" /> Your computer
                  </button>
                  <button
                    onClick={this.chooseFileFromGoogleDrive}
                    type="button"
                    className="btn p-2 dropdown-item"
                  >
                    <img src="/image/google_drive_icon.svg" alt="" /> Google
                    Drive
                  </button>
                </div>
              </div>
              <p>or drop a file here</p>
            </div>
          </div>
        </form>
      </div>
    );
  }
  chooseFileFromFilesystem() {
    this.fileInputElement.click();
  }
  chooseFileFromGoogleDrive() {
    window.gapi.auth2.authorize(
      {
        client_id: GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        fetch_basic_profile: false,
        immediate: true,
      },
      ({ access_token, error }) => {
        if (error) {
          return this.props.onError(error);
        }

        const filePicker = new window.google.picker.PickerBuilder()
          .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
          .addView(resolveGoogleFileType(this.props.type))
          .setOAuthToken(access_token)
          .setDeveloperKey(GOOGLE_API_KEY)
          .setCallback(data => {
            if (
              data[window.google.picker.Response.ACTION] ===
              window.google.picker.Action.PICKED
            ) {
              const [document] = data.docs || [];

              if (!document) {
                return this.props.onError(new Error('File is not selected'));
              }
              if (!this.isValidMimeType(document.mimeType)) {
                return this.props.onError(new Error('Unsupported file type'));
              }

              const formData = new FormData();
              formData.append('type', this.props.type);
              formData.append('origin', TASK_ORIGIN_GOOGLE_DRIVE);
              formData.append('source', document.id);
              formData.append('credentials', access_token);

              this.createTask(formData);
            }
          })
          .build();
        filePicker.setVisible(true);
      }
    );
  }
  handleChosenFile() {
    const formData = new FormData(this.formElement);
    const source = formData.get('source');

    if (!source) {
      return this.props.onError(new Error('File is not selected'));
    }
    if (!this.isValidMimeType(source.type)) {
      return this.props.onError(new Error('Unsupported file type'));
    }

    formData.append('type', this.props.type);
    formData.append('origin', TASK_ORIGIN_UPLOAD_FILE);

    this.createTask(formData);
  }
  handleDroppedFile(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.inDragging) {
      this.setState({ inDragging: false });
    }

    const [source] = e.dataTransfer.files || [];

    if (!source) {
      return this.props.onError(new Error('File is not selected'));
    }
    if (!this.isValidMimeType(source.type)) {
      return this.props.onError(new Error('Unsupported file type'));
    }

    const formData = new FormData(this.formElement);
    formData.set('source', source);
    formData.append('type', this.props.type);
    formData.append('origin', TASK_ORIGIN_UPLOAD_FILE);

    this.createTask(formData);
  }
  createTask(formData) {
    API.post('/api/tasks/', formData)
      .then(({ data: task }) => this.props.onTaskCreated(task))
      .catch(err => this.props.onError(err));
  }
  isValidMimeType(mimeType) {
    if (!mimeType) {
      return false;
    }

    const allowedMimeTypes = resolveMimeType(this.props.type);
    return !!~allowedMimeTypes.indexOf(mimeType);
  }
}

ConversionPickSource.propTypes = {
  type: PropTypes.string.isRequired,
  onTaskCreated: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default withRouter(
  connect(state => ({ user: state.auth.user || {} }))(ConversionPickSource)
);
