import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../assets/images/v2/logo.png';
import facebook from '../assets/images/icon-fb.svg';
import twitter from '../assets/images/icon-twitter.svg';

class Footer extends Component {
  shouldComponentUpdate() {
    return false;
  }
  renderCurrentYear() {
    const now = new Date();
    return <>{now.getFullYear()}</>;
  }
  render() {
    return (
      <footer id="footer">
        <div className={'container d-flex'}>
          <div className={'footer-logo'}>
            <img src={Logo} alt="SmartPDF" />
            <p>
              Copyright © {this.renderCurrentYear()} SmartPdf team
              <br />
              All rights reserved.
            </p>
            <div className={'social-icons'}>
              <a
                className={'social-link'}
                href={'https://facebook.com'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img src={facebook} />
              </a>
              <a
                className={'social-link'}
                href={'https://twitter.com'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <img src={twitter} />
              </a>
            </div>
          </div>
          <div className={'footer-menu d-flex'}>
            <div>
              <p>Product</p>
              <ul>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
            <div>
              <p>Legal</p>
              <ul>
                <li>
                  <Link to="/terms-of-service">Terms of service</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
              </ul>
            </div>
            <div>
              <p>User</p>
              <ul>
                <li>
                  <Link to="/account/profile">My profile</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={'footer-help'}>
            <p>Have a question?</p>
            <Link to="/feedback">Contact us</Link>
          </div>
        </div>
      </footer>
    );
  }
}

export default connect()(Footer);
