import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class FaqItem extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }
  clickHandler({ currentTarget }) {
    if (currentTarget.classList.contains('active')) {
      currentTarget.classList.remove('active');
      this.props.onToggle();
    } else {
      currentTarget.classList.add('active');
      this.props.onToggle(currentTarget);
    }
  }
  render() {
    const { children } = this.props;
    return <li onClick={this.clickHandler}>{children}</li>;
  }
}

FaqItem.propTypes = {
  onToggle: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
};

export default connect()(FaqItem);
