import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class ExcelToPdfDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - Excel to PDF Converter for Free'}
          description={
            'Convert Excel file into a PDF document easily with Smart PDF converter. Support both XLS and XLSX formats.'
          }
        />
        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Create PDF from Excel" />
            </p>
            <h4>Create PDF from Excel</h4>
            <p>
              Turning an Excel file into a PDF document is so easy with Smart
              PDF converter. Just upload XLSX in a box and receive a converted
              PDF file
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="A moment to convert Excel file" />
            </p>
            <h4>A moment to convert Excel file</h4>
            <p>
              How long does it take to convert an Excel doc to PDF file? You
              will spend just a moment to receive qualy PDF documents from XLS
              sheets
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Easy in use PDF converter" />
            </p>
            <h4>Easy in use PDF converter</h4>
            <p>
              In order to make Excel to PDF conversion you need simple steps to
              drop the XLS file and convert it into a PDF doc that takes seconds
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Secure Excel to PDF conversion" />
            </p>
            <h4>Secure Excel to PDF conversion</h4>
            <p>
              Considering your privacy we guarantee a secure XLS to PDF
              conversion using the modern cryptography for all uploading files
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="Excel into PDF from multi platform" />
            </p>
            <h4>Excel into PDF from multi platform</h4>
            <p>
              To proceed with PDF conversion it doesn’t matter what platform you
              are using. Smart PDF converter works with all operating systems
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default ExcelToPdfDetails;
