import React, { Component } from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import AccountProfile from './AccountProfile';
import AccountPassword from './AccountPassword';
import UserIcon from '../assets/images/v2/user.svg';
import PwdIcon from '../assets/images/v2/key.svg';

class Account extends Component {
  render() {
    return (
      <div className="d-flex flex-column user-page">
        <div className={'user-menu'}>
          <div className={'container'}>
            <ul className="list-group flex-row col-md-12">
              <li className="list-group-item">
                <Link to="/account/profile">
                  <img src={UserIcon} alt="Profile" />
                  Profile
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/account/password">
                  <img src={PwdIcon} alt="Password" />
                  Password
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-12">
          <div className={'container'}>
            <Switch>
              <Route
                exact
                path="/account"
                component={() => <Redirect to="/account/profile" />}
              />
              <Route exact path="/account/profile" component={AccountProfile} />
              <Route
                exact
                path="/account/password"
                component={AccountPassword}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Account;
