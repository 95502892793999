import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormSpinner from './FormSpinner';
import API from '../services/API';
import { signin } from '../redux/modules/auth';

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isProcessing: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.signin = this.signin.bind(this);
    this.modal = createRef();
  }
  render() {
    return (
      <>
        <div
          ref={this.modal}
          className="modal"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form
                onSubmit={this.signin}
                method="post"
                encType="multipart/form-data"
              >
                <div className="modal-header">
                  <h5 className="modal-title">Sign In</h5>
                  <Link
                    to="/"
                    className="close"
                    disabled={this.state.isProcessing}
                  >
                    <span aria-hidden="true">&times;</span>
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        disabled={this.state.isProcessing}
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        disabled={this.state.isProcessing}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        required
                      />
                    </div>
                  </div>
                  <FormSpinner shown={this.state.isProcessing} />
                </div>
                <div className="modal-footer">
                  <Link
                    to="/reset-password"
                    className="btn btn-sm btn-link"
                    disabled={this.state.isProcessing}
                  >
                    Forgot password?
                  </Link>
                  <Link
                    to="/signup"
                    className="btn btn-secondary"
                    disabled={this.state.isProcessing}
                  >
                    Sign Up
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.isProcessing}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    window.$(this.modal.current).modal('show');
  }
  componentWillUnmount() {
    window.$(this.modal.current).modal('hide');
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  signin(e) {
    e.preventDefault();
    this.setState({ isProcessing: true });

    API.post('/api/tokens/', {
      email: this.state.email,
      password: this.state.password,
    })
      .then(({ data }) => {
        this.props.dispatch(signin(data));
        this.props.history.push('/');
      })
      .catch(() => {})
      .finally(() => this.setState({ ...this.state, isProcessing: false }));
  }
}

Signin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(Signin));
