import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import API from '../services/API';
import { success } from '../redux/modules/notifications';

class AccountPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConfirmation: '',
      isProcessing: false,
    };
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleNewPasswordConfirmationChange =
      this.handleNewPasswordConfirmationChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }
  render() {
    return (
      <>
        <h3>Change password</h3>
        <p>Please fill all the fields correctly</p>
        <form
          onSubmit={this.changePassword}
          className="pl-5"
          method="post"
          encType="multipart/form-data"
        >
          <div className="form-row">
            <div className="form-group col-12">
              <p>New password:</p>
              <input
                value={this.state.newPassword}
                onChange={this.handleNewPasswordChange}
                disabled={this.state.isProcessing}
                type="password"
                className="form-control"
                minLength="6"
                maxLength="32"
                pattern=".*"
                required
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <p>New password confirmation:</p>
              <input
                value={this.state.newPasswordConfirmation}
                onChange={this.handleNewPasswordConfirmationChange}
                disabled={this.state.isProcessing}
                type="password"
                className="form-control"
                minLength="6"
                maxLength="32"
                pattern=".*"
                required
              />
            </div>
          </div>
          <button
            onClick={this.changePasword}
            type="submit"
            className="btn btn-primary"
            disabled={this.state.isProcessing}
          >
            {this.state.isProcessing && (
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Change password
          </button>
        </form>
      </>
    );
  }
  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value });
  }
  handleNewPasswordConfirmationChange(e) {
    this.setState({ newPasswordConfirmation: e.target.value });
  }
  changePassword(e) {
    e.preventDefault();
    this.setState({ isProcessing: true });
    API.post('/api/update-password-requests/', {
      newPassword: this.state.newPassword,
      newPasswordConfirmation: this.state.newPasswordConfirmation,
    })
      .then(() => {
        this.props.dispatch(
          success('Visit your mailbox and follow the instruction')
        );
        this.setState({ newPassword: '', newPasswordConfirmation: '' });
      })
      .catch(() => {})
      .finally(() => this.setState({ isProcessing: false }));
  }
}

AccountPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(AccountPassword);
