import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserNavigation from './UserNavigation';

class Navigation extends Component {
  toggleMenu() {
    let elm = document.querySelector('ul.nav'),
      burger = document.querySelector('.burger');
    if (elm.classList.contains('active')) {
      burger.classList.remove('open');
      elm.classList.remove('active');
    } else {
      elm.classList.add('active');
      burger.classList.add('open');
    }
  }

  render() {
    return (
      <>
        <ul className={'nav'}>
          <div onClick={this.toggleMenu}>
            <li className="nav-item mr-2">
              <Link to="/feedback" className="btn p-2 pl-3 pr-3">
                Contact Us
              </Link>
            </li>
            <li className="nav-item mr-2">
              <a href="/blog/" className="btn p-2 pl-3 pr-3">
                Blog
              </a>
            </li>
            <li className="nav-item mr-2">
              <a href="/#install" className="btn p-2 pl-3 pr-3">
                Install
              </a>
            </li>
          </div>
        </ul>
        <nav role="navigation" className="flex-row">
          <a className={'burger'} onClick={this.toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </a>
          <ul className="nav">
            <li className="nav-item">
              <UserNavigation />
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default Navigation;
