import React, { Component } from 'react';
import FaqSection from './FaqSection';
import MetaChanger from './MetaChanger';

class FAQ extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return (
      <div className={'inner-text'}>
        <MetaChanger
          title={'Smart PDF - PDF Converter for Word, Excel, PPT, JPG'}
          description={
            'Online PDF Converter for most popular formats. Easily convert from PDF and to PDF for free. Supports converting in Word, Excel, PDF, PPT and JPG.'
          }
        />
        <FaqSection />
      </div>
    );
  }
}

export default FAQ;
