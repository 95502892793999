import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class PdfToExcelDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - PDF to Excel Converter for Free'}
          description={
            'Advanced online PDF converter to turn files from PDF to Excel sheet. Smart PDF is a free service for online converting.'
          }
        />

        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Accurate Excel from PDF conversion" />
            </p>
            <h4>Accurate Excel from PDF conversion</h4>
            <p>
              We create an advanced PDF converter to provide you with a
              high-grade service for converting files from PDF to Excel sheet
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="Save time during create PDF" />
            </p>
            <h4>Save time during create PDF</h4>
            <p>
              We convert both formats PDF to XLS and PDF into XLSX easily and
              quickly to allow you continue your work faster than ever
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Mac OS, Windows and Linux" />
            </p>
            <h4>Mac OS, Windows and Linux</h4>
            <p>
              All these platforms are supported by Smart PDF converter and make
              an Excel sheet from PDF file conversion accurately and for free
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Safety using for everyone" />
            </p>
            <h4>Safety using for everyone</h4>
            <p>
              Trust us any documents, as we don’t store them for more than an
              hour. All converted PDF to XLSX files are deleted during an hour.
              For more information please read our Privacy Policy
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="Friendly design of converter" />
            </p>
            <h4>Friendly design of converter</h4>
            <p>
              Smart PDF converter will be suitable for everyone, as we take all
              our efforts to ensure a light process of PDF to Excel conversion.
              Just spend a moment to upload a PDF file
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default PdfToExcelDetails;
