import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DocFileImg from '../assets/images/v2/doc.svg';
import XlsFileImg from '../assets/images/v2/xls.svg';
import PptFileImg from '../assets/images/v2/ppt.svg';
import JpgFileImg from '../assets/images/v2/jpg.svg';

class ConversionOptions extends Component {
  render() {
    return (
      <section className={'conversion-options'}>
        <div className={'container'}>
          <ul>
            <li>
              <Link to="/word-to-pdf">
                <img src={DocFileImg} alt="Word to PDF" />
                Word to PDF
              </Link>
            </li>
            <li>
              <Link to="/excel-to-pdf">
                <img src={XlsFileImg} alt="Excel to PDF" />
                Excel to PDF
              </Link>
            </li>
            <li>
              <Link to="/ppt-to-pdf">
                <img src={PptFileImg} alt="PPT to PDF" />
                PPT to PDF
              </Link>
            </li>
            <li>
              <Link to="/jpeg-to-pdf">
                <img src={JpgFileImg} alt="JPEG to PDF" />
                JPEG to PDF
              </Link>
            </li>
            <li>
              <Link to="/pdf-to-word">
                <img src={DocFileImg} alt="PDF to Word" />
                PDF to Word
              </Link>
            </li>
            <li>
              <Link to="/pdf-to-excel">
                <img src={XlsFileImg} alt="PDF to Excel" />
                PDF to Excel
              </Link>
            </li>
            <li>
              <Link to="/pdf-to-ppt">
                <img src={PptFileImg} alt="PDF to PPT" />
                PDF to PPT
              </Link>
            </li>
            <li>
              <Link to="/pdf-to-jpeg">
                <img src={JpgFileImg} alt="PDF to JPEG" />
                PDF to JPEG
              </Link>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default connect()(ConversionOptions);
