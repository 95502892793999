import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { revoke } from '../redux/modules/notifications';
import './Notification.css';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.element = React.createRef();
    this.timer = null;
  }
  componentDidUpdate() {
    this.timer = setTimeout(() => {
      this.props.dispatch(revoke());
    }, 3000);
    window.$(this.element.current).on('close.bs.alert', () => {
      clearTimeout(this.timer);
      this.props.dispatch(revoke());
    });
  }
  render() {
    return (
      <>
        {this.props.notification && (
          <div className="notifications">
            <div
              className={`alert alert-` + this.props.notification.level}
              ref={this.element}
              role="alert"
              key={Math.random()}
            >
              <button className="close" data-dismiss="alert">
                ×
              </button>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                className="bi bi-exclamation-triangle"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.938 2.016a.146.146 0 0 0-.054.057L1.027 13.74a.176.176 0 0 0-.002.183c.016.03.037.05.054.06.015.01.034.017.066.017h13.713a.12.12 0 0 0 .066-.017.163.163 0 0 0 .055-.06.176.176 0 0 0-.003-.183L8.12 2.073a.146.146 0 0 0-.054-.057A.13.13 0 0 0 8.002 2a.13.13 0 0 0-.064.016zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
                />
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
              </svg>
              <span className="p-2">{this.props.notification.message}</span>
            </div>
          </div>
        )}
      </>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default connect(state => ({ notification: state.notifications[0] }))(
  Notification
);
