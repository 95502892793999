import React, { Component } from 'react';
import { connect } from 'react-redux';
import PromoAppIcon from '../assets/images/v2/sign.png';
import InstallAndroid from '../assets/images/v2/gplay.svg';
import InstallChrome from '../assets/images/v2/chrome.svg';
import InstallFirefox from '../assets/images/v2/firefox.svg';
import InstallEdge from '../assets/images/v2/edge.svg';
import InstallIos from '../assets/images/v2/apple.svg';

class InstallSection extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return (
      <section id={'install'} className={'install-app'}>
        <div className={'container'}>
          <div className="image">
            <img src={PromoAppIcon} alt="SmartPDF apps" />
          </div>
          <div className="placement">
            <h4>Available for desktop & mobile</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              eu diam a ante cursus feugiat. Morbi placerat erat aliquet leo
              laoreet, faucibus auctor dolor maximus. Maecenas dapibus nibh
              aliquet, blandit nibh sed, placerat tellus. Morbi fermentum
              eleifend feugiat. In pharetra ac leo nec egestas.
            </p>
            <ul className={'install-list'}>
              <li>
                <a
                  target={'_blank'}
                  href={
                    'https://play.google.com/store/apps/details?id=com.createpdf.smartpdf'
                  }
                  rel="noopener noreferrer"
                >
                  <img
                    src={InstallAndroid}
                    alt="SmartPDF on Google Play Store"
                  />{' '}
                  Android
                </a>
              </li>
              <li>
                <a
                  target={'_blank'}
                  href={
                    'https://apps.apple.com/us/app/smart-pdf-pdf-converter-tool/id1558999802'
                  }
                  rel="noopener noreferrer"
                >
                  <img src={InstallIos} alt="SmartPDF on Apple App Store" /> iOS
                </a>
              </li>
              <li>
                <a
                  target={'_blank'}
                  href={
                    'https://chrome.google.com/webstore/detail/smart-pdf-pdf-converter-t/benjmhnicaokfjhdoolkkjiiccceigdm'
                  }
                  rel="noopener noreferrer"
                >
                  <img src={InstallChrome} alt="SmartPDF on Chrome Web Store" />{' '}
                  Chrome
                </a>
              </li>
              <li>
                <a
                  target={'_blank'}
                  href={
                    'https://addons.mozilla.org/en-US/firefox/addon/smart-pdf-files-converter-tool/'
                  }
                  rel="noopener noreferrer"
                >
                  <img
                    src={InstallFirefox}
                    alt="SmartPDF on Add-ons for Firefox"
                  />{' '}
                  Firefox
                </a>
              </li>
              <li>
                <a
                  target={'_blank'}
                  href={
                    'https://microsoftedge.microsoft.com/addons/detail/smart-pdf-files-convert/cbccllofgnpbofmfmfdpdnfhgbcljilp'
                  }
                  rel="noopener noreferrer"
                >
                  <img
                    src={InstallEdge}
                    alt="SmartPDF on Microsoft Edge Add-ons"
                  />{' '}
                  Edge
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default connect()(InstallSection);
