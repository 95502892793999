import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TypeNavigation from '../TypeNavigation';
import { error } from '../../redux/modules/notifications';

class ConversionHandleFail extends Component {
  componentDidMount() {
    const { message } = this.props;

    if (message) {
      this.props.dispatch(error(message));
    }
  }
  render() {
    return (
      <div className="view">
        <TypeNavigation />
        <div className="p-5 view-body">
          <div className="col-md-4 offset-4">
            <div className="d-flex flex-column">
              <img src="/image/error_icon.svg" alt="" className="mb-3" />
              <button
                onClick={this.props.tryAgain}
                className="p-2 btn try-again"
              >
                Try again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConversionHandleFail.propTypes = {
  tryAgain: PropTypes.func.isRequired,
  message: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(ConversionHandleFail);
