import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Typewriter from 'typewriter-effect/dist/core';
import ConversionChooseType from './Converter/ConversionChooseType';
import ConversionPickSource from './Converter/ConversionPickSource';
import ConversionFollowProgress from './Converter/ConversionFollowProgress';
import ConversionHandleSuccess from './Converter/ConversionHandleSuccess';
import ConversionHandleFail from './Converter/ConversionHandleFail';
import WordToPdfDetails from './Converter/WordToPdfDetails';
import ExcelToPdfDetails from './Converter/ExcelToPdfDetails';
import PptToPdfDetails from './Converter/PptToPdfDetails';
import JpegToPdfDetails from './Converter/JpegToPdfDetails';
import PdfToWordDetails from './Converter/PdfToWordDetails';
import PdfToExcelDetails from './Converter/PdfToExcelDetails';
import PdfToPptDetails from './Converter/PdfToPptDetails';
import PdfToJpegDetails from './Converter/PdfToJpegDetails';
import MainDocImg from '../assets/images/v2/ico_word.png';
import MainXlsImg from '../assets/images/v2/ico_excel.png';
import MainPptImg from '../assets/images/v2/ico_ppt.png';
import MainPdfImg from '../assets/images/v2/ico_pdf.png';
import MetaChanger from './MetaChanger';
import FaqSection from './FaqSection';

window.addEventListener('DOMContentLoaded', () => {
  window.gapi.load('auth2', () => {});
  window.gapi.load('picker', () => {});
});

class Converter extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.onTaskCreated = this.onTaskCreated.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.state = {
      showDetails: true,
      view: this.props.type ? (
        <ConversionPickSource
          type={this.props.type}
          onTaskCreated={this.onTaskCreated}
          onSuccess={this.onSuccess}
          onError={this.onError}
        />
      ) : (
        <ConversionChooseType />
      ),
      isMain: this.props.ismain,
    };
  }

  handleScroll() {
    this.setState({ showDetails: true });
  }

  componentDidMount() {
    // window.addEventListener('scroll', this.handleScroll);
    // new Typewriter('#typewriter', {
    //   strings: ['easier', 'productively', 'quick', 'efficient'],
    //   autoStart: true,
    //   loop: true,
    // });
  }

  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <>
        <main
          className="container d-flex flex-column align-items-center"
          style={{ minHeight: '100%' }}
        >
          {this.state.isMain && (
            <>
              <MetaChanger
                title={
                  'Smart PDF - Convert Files from PDF and to PDF Format Easily'
                }
                description={
                  'Online PDF Converter for most popular formats. Easily convert from PDF and to PDF for free. Supports converting in Word, Excel, PDF, PPT and JPG.'
                }
              />
              <img className={'ico-word'} src={MainDocImg} alt="DOC,DOCX" />
              <img className={'ico-xls'} src={MainXlsImg} alt="XLS,XLSX" />
              <img className={'ico-ppt'} src={MainPptImg} alt="PPT,PPTX" />
              <img className={'ico-pdf'} src={MainPdfImg} alt="PDF" />
            </>
          )}
          <div className="d-flex flex-column col-md-9 m-auto offset-1 p-3 mb-5">
            <div className="d-flex flex-row col-md-12 justify-content-center mb-4">
              <h2>
                Smart PDF Converter <br />
                We are gonna make your work easier
                {/*<span id={'typewriter'}>easier</span>*/}
              </h2>
            </div>
            {this.state.view}
            {this.state.showDetails && (
              <Switch>
                <Route exact path="/word-to-pdf" component={WordToPdfDetails} />
                <Route
                  exact
                  path="/excel-to-pdf"
                  component={ExcelToPdfDetails}
                />
                <Route exact path="/ppt-to-pdf" component={PptToPdfDetails} />
                <Route exact path="/jpeg-to-pdf" component={JpegToPdfDetails} />
                <Route exact path="/pdf-to-word" component={PdfToWordDetails} />
                <Route
                  exact
                  path="/pdf-to-excel"
                  component={PdfToExcelDetails}
                />
                <Route exact path="/pdf-to-ppt" component={PdfToPptDetails} />
                <Route exact path="/pdf-to-jpeg" component={PdfToJpegDetails} />
              </Switch>
            )}
          </div>
        </main>
        {!this.state.isMain && <FaqSection />}
      </>
    );
  }

  tryAgain() {
    this.setState({
      view: (
        <ConversionPickSource
          type={this.props.type}
          onTaskCreated={this.onTaskCreated}
          onSuccess={this.onSuccess}
          onError={this.onError}
        />
      ),
    });
  }

  onTaskCreated(task) {
    this.setState({
      view: (
        <ConversionFollowProgress
          task={task}
          onSuccess={this.onSuccess}
          onError={this.onError}
        />
      ),
    });
  }

  onSuccess(task) {
    this.setState({
      view: <ConversionHandleSuccess task={task} startNew={this.tryAgain} />,
    });
  }

  onError(error) {
    this.setState({
      view: (
        <ConversionHandleFail
          message={error.message}
          tryAgain={this.tryAgain}
        />
      ),
    });
  }
}

Converter.propTypes = {
  type: PropTypes.string,
  ismain: PropTypes.bool,
};

export default Converter;
