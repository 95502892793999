import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormSpinner from './FormSpinner';
import API from '../services/API';
import { success } from '../redux/modules/notifications';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      isProcessing: false,
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.signup = this.signup.bind(this);
    this.modal = createRef();
  }
  render() {
    return (
      <>
        <div
          ref={this.modal}
          className="modal fade"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <form
                onSubmit={this.signup}
                method="post"
                encType="multipart/form-data"
              >
                <div className="modal-header">
                  <h5 className="modal-title">Sign Up</h5>
                  <Link
                    to="/"
                    className="close"
                    disabled={this.state.isProcessing}
                  >
                    <span aria-hidden="true">&times;</span>
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        disabled={this.state.isProcessing}
                        type="email"
                        className="form-control"
                        placeholder="Email address"
                        minLength="3"
                        maxLength="255"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        disabled={this.state.isProcessing}
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        minLength="6"
                        maxLength="255"
                        required
                      />
                      <small className="form-text text-muted">
                        Password should not be shorten than 6 characters,
                        contains at least one latin alphabet symbolm one digit
                        symbols and should not match with the Email address
                        field
                      </small>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.firstName}
                        onChange={this.handleFirstNameChange}
                        disabled={this.state.isProcessing}
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        minLength="1"
                        maxLength="255"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <input
                        value={this.state.lastName}
                        onChange={this.handleLastNameChange}
                        disabled={this.state.isProcessing}
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        minLength="1"
                        maxLength="255"
                        required
                      />
                    </div>
                  </div>
                  <small className="form-text text-muted">
                    By by signing up you agree with our{' '}
                    <a href="/terms-of-service" target="_blank">
                      Terms of Service
                    </a>
                  </small>
                  <FormSpinner shown={this.state.isProcessing} />
                </div>
                <div className="modal-footer">
                  <Link
                    to="/signin"
                    className="btn btn-sm btn-link"
                    disabled={this.state.isProcessing}
                  >
                    I already have an account
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={this.state.isProcessing}
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
  componentDidMount() {
    window.$(this.modal.current).modal('show');
  }
  componentWillUnmount() {
    window.$(this.modal.current).modal('hide');
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handleFirstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }
  handleLastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }
  signup(e) {
    e.preventDefault();
    this.setState({ isProcessing: true });
    API.post('/api/users/', {
      email: this.state.email,
      password: this.state.password,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    })
      .then(() => {
        this.props.dispatch(success('The account successfully created.'));
        this.props.history.push('/signin');
      })
      .catch(() => {})
      .finally(() => this.setState({ isProcessing: false }));
  }
}

Signup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(connect()(Signup));
