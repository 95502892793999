import React, { Component } from 'react';
import WandIcon from '../../assets/images/v2/wand.svg';
import TimerIcon from '../../assets/images/v2/timer.svg';
import PdfIcon from '../../assets/images/v2/PDF_2.svg';
import ShieldIcon from '../../assets/images/v2/shield.svg';
import LayersIcon from '../../assets/images/v2/layers2.svg';
import MetaChanger from '../MetaChanger';
import AdSenseBanner from '../AdSenseBanner';

class PptToPdfDetails extends Component {
  render() {
    return (
      <>
        <AdSenseBanner />
        <MetaChanger
          title={'Smart PDF - PPT to PDF Online Converter'}
          description={
            'It’s a free online PDF converter, that converts from PPT to PDF and vice versa without registration and subscription.'
          }
        />

        <div className={'details'}>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={WandIcon} alt="Both PPT and PPTX are supported" />
            </p>
            <h4>Both PPT and PPTX are supported</h4>
            <p>
              Here you can convert PPT to PDF file as well as PPTX into PDF
              simply and without any effort. Just drop the selected file in the
              box and download the PDF
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={TimerIcon} alt="Few clicks from PPT slide to PDF" />
            </p>
            <h4>Few clicks from PPT slide to PDF</h4>
            <p>
              In order to convert Microsoft PPT to PDF document simply follow a
              few steps to download PPT file and press convert PDF button
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={PdfIcon} alt="Simple PDF converter for free" />
            </p>
            <h4>Simple PDF converter for free</h4>
            <p>
              Our service is a free PDF converter, thus you are able to
              continuously convert from PPT to PDF and vice versa without
              registration and subscription
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={ShieldIcon} alt="Security conversion is ensured" />
            </p>
            <h4>Security conversion is ensured</h4>
            <p>
              Your security is the foremost for us. Therefore we use a modern
              cryptography for all requests about PPT to PDF converter according
              to our Privacy Policy
            </p>
          </div>
          <div className={'details-item'}>
            <p className={'details-img'}>
              <img src={LayersIcon} alt="All platforms supported" />
            </p>
            <h4>All platforms supported</h4>
            <p>
              Use Windows, Linux and Mac OS to convert PPT doc to PDF file for
              free spending just a few seconds. Converting PPT slides has never
              been so easy
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default PptToPdfDetails;
